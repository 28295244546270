.navbar {
  background-color: #282c34;
  height: 80px;
  display: flex;
  align-items: center;
}

.navbar-logo {
  margin-left: 20px;
  height: 70px;
}
