.hero {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  background: url('../../hero.png') no-repeat center center;
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
}

.text {
  margin-left: auto;
  font-size: 2rem;
  color: #fff;
  font-weight: bold;
  width: 450px;
  text-align: center;
  margin: auto;
}
.outer-container {
  position: absolute; /* Added this */
  top: 0; /* Added this */
  left: 0; /* Added this */
  width: 100%; /* Added this */
  height: 100%; /* Adjusted this */
  display: flex;
  align-items: flex-start;
}

.inner-container {
  margin-left: 0%; /* Start the inner container from the halfway point */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center horizontally within the right half */
  align-items: center; /* Center vertically */
  width: 100%; /* Ensure the inner container only takes up the right half */
}
.inner-container span {
  display: block; /* This ensures spans appear on top of one another */
}

.title {
  font-size: 3rem;
}
